import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import headerImg from '../assets/img/header-img.svg';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const toRotate = ['Virtual Artificial Intelligence...', 'Dreams!'];
  const period = 300;

  const tick = useCallback(() => {
      let i = loopNum % toRotate.length;
      let fullText = toRotate[i];
      let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

      setText(updatedText);

      if (isDeleting) {
        setDelta((prevDelta) => prevDelta / 2);
      }

      if (!isDeleting && updatedText === fullText) {
        setIsDeleting(true);
        setDelta(period);
      } else if (isDeleting && updatedText === '') {
        setIsDeleting(false);
        setLoopNum((prevLoopNum) => prevLoopNum + 1);
        setDelta(300);
      }
    }, [loopNum, toRotate, isDeleting, text, setDelta, setIsDeleting, setText, setLoopNum]);

  useEffect(() => {
      const ticker = setInterval(() => {
        tick();
      }, delta);

      return () => {
        clearInterval(ticker);
      };
    }, [delta, tick]);

    return (
      <section className="banner" id="home">
        <Container>
          <Row className="aligh-items-center">
            <Col xs={12} md={6} xl={7}>
              <TrackVisibility>
                {() => (
                  <div className="animateanimated animatefadeIn">
                    <span className="tagline">THE</span>
                    <h1>
                      Unknown{' '}
                      <span data-rotate='[ "Virtual Artificial Intelligence...", "Dreams!" ]'>
                        <span className="wrap">{text}</span>
                      </span>
                    </h1>
                    <p><span role="img" aria-label="alien monster emoji">👾</span>More than just a LOGO𖤍</p>
                    <a href="#connect">
                      <button href="#connect">Let's Connect.<ArrowRightCircle size={25} /></button>
                    </a>
                  </div>
                )}
              </TrackVisibility>
            </Col>
            <Col xs={12} md={6} xl={5}>
              <TrackVisibility>
                {() => (
                  <div className="animateanimated animatezoomIn">
                    <img src={headerImg} alt="Header Img" />
                  </div>
                )}
              </TrackVisibility>
            </Col>
          </Row>
        </Container>
      </section>
    );
};