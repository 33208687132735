import React from 'react';
import 'animate.css';
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import { ProjectCard2 } from "./ProjectCard";
import { ProjectCard3 } from "./ProjectCard";
import gd from "../assets/img/g&d.png";
import ff from "../assets/img/f&f.png";
import bs from "../assets/img/b&s.png";
import ai from "../assets/img/unknownbot.png";
import vr from "../assets/img/v&r.png";
import vp from "../assets/img/vp.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import TrackVisibility from 'react-on-screen';
import ak1 from "../assets/img/01.png";
import x from "../assets/img/twitter-x.png";
import wb from "../assets/img/w.png";
// import ufox2 from "../assets/img/ufo x02.png";
// import ufox3 from "../assets/img/ufo x03.png";
// import ufox4 from "../assets/img/ufo x04.png";
// import ufox5 from "../assets/img/ufo x05.png";
// import ufox6 from "../assets/img/ufo x06.png";
// import ufox7 from "../assets/img/ufo x07.png";
// import ufox8 from "../assets/img/ufo x08.png";
// import ufox9 from "../assets/img/ufo x09.png";

export const VAI = () => {

  const projects = [
    {
      title: "Visioning & Research",
      description: "Research and analyze your competitors to identify common themes in their logos and branding. This will help you find trends in your industry, differentiate your brand, and position it strategically within the marketplace.",
      imgUrl: vr,
    },
    {
      title: "Brainstorming & Sketching",
      description: "Brainstorm ideas based on the research by creating rough sketches and exploring various concepts that reflect your brand's essence, focusing on symbols or imagery that can represent your core values and message.",
      imgUrl: bs,
    },
    {
      title: "Design & Refining",
      description: "Narrowing down sketches to a few strong ideas, picking the strongest concepts, and refining them while considering elements like color, typography, and simplicity is essential. A good logo should be versatile, timeless, and distinctive.",
      imgUrl: ai,
    },
    {
      title: "Feedback & Finalization",
      description: "After the design has been refined and approved by the customer, we will finalize the logo, ensuring that it is versatile, scalable, and effective across different mediums (print, digital, etc.). We will iterate based on feedback before settling on a final design.",
      imgUrl: ff,
    },
    {
      title: "Delivery & Guidelines",
      description: "After delivery, guidelines will be provided on how to use the logo effectively and maintain its integrity across different platforms. Additionally, we will offer ongoing support and assistance as needed.",
      imgUrl: gd,
    },
    {
      title: "Validate & Protect",
      description: "Trademarking your logo to protect it legally, enhancing your business's overall stability, and increasing visibility and awareness are all crucial components of a successful branding strategy. Together, these elements work to strengthen your brand's presence in the market.",
      imgUrl: vp,
    },
  ];
////////////////////////////////////////////////////
  const projects2 = [
    {
      title: "AK Empire",
      link: "https://twitter.com/Kevn1X/status/1785804666082886043?t=l6v4uarwNqjUG9lQyCO4tw&s=19",
      description: "Click!",
      imgUrl: ak1,
      id: "@Rare4art",
      link2: "https://t.me/Rare4art"
    },
    {
      title: "𝕏",
      link: "https://twitter.com/UnknownVAI/status/1827952398398345242",
      description: "Click!",
      imgUrl: x,
      id: "@Elonmusk",
      link2: "https://twitter.com/elonmusk"
    },
    {
      title: "Warren Buffett",
      // link: "https://twitter.com/Kevn1X/status/1785804666082886043?t=l6v4uarwNqjUG9lQyCO4tw&s=19",
      description: "Upcoming!",
      imgUrl: wb,
      id: "@WarrenBuffett",
      link2: "https://twitter.com/WarrenBuffett"
    },
  ];

  ///////////////////////////////////////////////////
  const projects3 = [
    {
      title: "AK Empire",
      link: "https://twitter.com/Kevn1X/status/1785804666082886043?t=l6v4uarwNqjUG9lQyCO4tw&s=19",
      description: "💎",
      imgUrl: ak1,
      id: "@Rare4art",
      link2: "https://t.me/Rare4art"
    },
  ];  
  return (
    <section className="vai" id="vai">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>VAI</h2>
                <p>"Aligning brand's core values, mission, and target audience."</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Steps</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Stories</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">RaRi</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                    <span>
                     <span role="img" aria-label="star">⭐️</span>
                     <span role="img" aria-label="star">⭐️</span>
                     <span role="img" aria-label="star">⭐️</span>
                     <span role="img" aria-label="star">⭐️</span>
                     <span role="img" aria-label="star">⭐️</span>
                     <span role="img" aria-label="star">⭐️</span>
                    </span>
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                     </Tab.Pane>
                      <Tab.Pane eventKey="second">
                      <span>Everyone has a Story<span role="img" aria-label="book">📖</span></span>
                      <Row>
                        {
                          projects2.map((project2, index) => {
                            return (
                              <ProjectCard2
                                key={index}
                                {...project2}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <span><span role="img" aria-label="gem">💎</span>All Time High<span role="img" aria-label="gem">💎</span></span>
                      <Row>
                        {
                          projects3.map((project3, index) => {
                            return (
                              <ProjectCard3
                                key={index}
                                {...project3}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt=""></img>
    </section>
  )
}
